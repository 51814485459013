[
  {
    "names": [
      "tailwind.css"
    ],
    "generatedName": "tailwind.8584b0956884a054abee4414937fdf9b.css"
  },
  {
    "names": [
      "pureleap-web--__index.map"
    ],
    "generatedName": "-__index.22712ac0b47d68e69642d85917d01217.map.json"
  },
  {
    "names": [
      "pureleap-web--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.dd56f3cfe8514d644025c60bf2e0c858.js"
  },
  {
    "names": [
      "pureleap-web-posts.map"
    ],
    "generatedName": "posts.7c154568e9a7799e17a0236417467056.map.json"
  },
  {
    "names": [
      "pureleap-web-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.361b8c5b69071a728cedca9a551fea85.js"
  },
  {
    "names": [
      "pureleap-web-about.map"
    ],
    "generatedName": "about.70d333f37b222aa84e1b2ea4f8e99f7b.map.json"
  },
  {
    "names": [
      "pureleap-web-about-bundle.js"
    ],
    "generatedName": "about-bundle.e7bd01f2b3839ebf70bc3d12332f64eb.js"
  },
  {
    "names": [
      "pureleap-web-blog.map"
    ],
    "generatedName": "blog.0581a325f9df18702fabc65ae69e185c.map.json"
  },
  {
    "names": [
      "pureleap-web-blog-bundle.js"
    ],
    "generatedName": "blog-bundle.91b790b7dadd37e98b21c9548377a9d2.js"
  },
  {
    "names": [
      "pureleap-web-categories-_id_.map"
    ],
    "generatedName": "categories-_id_.dd78e311d31966fb3ee8a06cd522f6a4.map.json"
  },
  {
    "names": [
      "pureleap-web-categories-_id_-bundle.js"
    ],
    "generatedName": "categories-_id_-bundle.c680870beee641564be443c49831cb4a.js"
  },
  {
    "names": [
      "pureleap-web-robots_txt.map",
      "pureleap-web-prod-robots_txt.map"
    ],
    "generatedName": "robots_txt.e657c1c95e2a4b54e72879594aa4a2d2.map.json"
  },
  {
    "names": [
      "pureleap-web-robots_txt-bundle.js",
      "pureleap-web-prod-robots_txt-bundle.js"
    ],
    "generatedName": "robots_txt-bundle.58f543efa98d1cdc7f53eab45f882751.js"
  },
  {
    "names": [
      "pureleap-web-sitemap_xml.map",
      "pureleap-web-prod-sitemap_xml.map"
    ],
    "generatedName": "sitemap_xml.c8bab6179b7f9ab5030465a564dfda9f.map.json"
  },
  {
    "names": [
      "pureleap-web-sitemap_xml-bundle.js",
      "pureleap-web-prod-sitemap_xml-bundle.js"
    ],
    "generatedName": "sitemap_xml-bundle.098115e6feb6b513140080074e6dddfd.js"
  },
  {
    "names": [
      "pureleap-web-tags-_id_.map"
    ],
    "generatedName": "tags-_id_.19b53eeb47a4990f912d1de73e859602.map.json"
  },
  {
    "names": [
      "pureleap-web-tags-_id_-bundle.js"
    ],
    "generatedName": "tags-_id_-bundle.3aeb6e36bff2837d9a08036852648b22.js"
  },
  {
    "names": [
      "pureleap-web-_post__.map"
    ],
    "generatedName": "_post__.142b1fae8db0d43eda5678f2d1e5de55.map.json"
  },
  {
    "names": [
      "pureleap-web-_post__-bundle.js"
    ],
    "generatedName": "_post__-bundle.f6328088cd85ab27ae465915da267172.js"
  },
  {
    "names": [
      "pureleap-web-prod--__index.map"
    ],
    "generatedName": "-__index.94584e5de723ca09a8e57ffa0cb7832c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.8cf8f7a78f6f50312b3239acb15b88db.js"
  },
  {
    "names": [
      "pureleap-web-prod-about.map"
    ],
    "generatedName": "about.ae6150ace55c3777cd2964e75a892fb7.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-about-bundle.js"
    ],
    "generatedName": "about-bundle.2bb4654f4683a7aef22452e1c4ff7ecd.js"
  },
  {
    "names": [
      "pureleap-web-prod-blog.map"
    ],
    "generatedName": "blog.f47bbdb4b3996c0b9b806e643f35d58c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-blog-bundle.js"
    ],
    "generatedName": "blog-bundle.f555e97b0d8f2a53f4f011bc1593ea20.js"
  },
  {
    "names": [
      "pureleap-web-prod-categories-_id_.map"
    ],
    "generatedName": "categories-_id_.e27657e552f04d884bd0da3bb5da2674.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-categories-_id_-bundle.js"
    ],
    "generatedName": "categories-_id_-bundle.8662db602a469365964a42f246fb5e77.js"
  },
  {
    "names": [
      "pureleap-web-prod-tags-_id_.map"
    ],
    "generatedName": "tags-_id_.6db141066ec173b69c2cd7974959b76a.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-tags-_id_-bundle.js"
    ],
    "generatedName": "tags-_id_-bundle.d2e05f053617e82cd1a7f8fb2f066a29.js"
  },
  {
    "names": [
      "pureleap-web-prod-_post__.map"
    ],
    "generatedName": "_post__.d3353aef8c47691f665733735fd9431c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-_post__-bundle.js"
    ],
    "generatedName": "_post__-bundle.70113f846c29263fa4418be59fd53b33.js"
  },
  {
    "names": [
      "pureleap-web-prod-objecthub.map",
      "pureleap-web-objecthub.map"
    ],
    "generatedName": "objecthub.3db0ed1974606d0275550677fcf16fe2.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-objecthub-bundle.js",
      "pureleap-web-objecthub-bundle.js"
    ],
    "generatedName": "objecthub-bundle.1a837f97d9f64ee9630bf50ec427a963.js"
  },
  {
    "names": [
      "pureleap-web-prod-recipes.map"
    ],
    "generatedName": "recipes.ed953548b7b1755cdc9c68486f39de3c.map.json"
  },
  {
    "names": [
      "pureleap-web-prod-recipes-bundle.js"
    ],
    "generatedName": "recipes-bundle.ec9a8d37c9043f04fe1345f949d839dc.js"
  },
  {
    "names": [
      "pureleap-web-recipes.map"
    ],
    "generatedName": "recipes.2e9d7888d8a67c4e06249e0b9b2418fa.map.json"
  },
  {
    "names": [
      "pureleap-web-recipes-bundle.js"
    ],
    "generatedName": "recipes-bundle.0755bffaa5041376d6a3fe992177737d.js"
  }
]
{
  "$schema": "./schemas/package.schema.json",
  "name": "web-pureleap",
  "template": "server-side-rendering",
  "templateVersion": "0.1.16",
  "configuration": {},
  "deployments": [
    {
      "name": "dev",
      "configuration": {
        "lambdas": {
          "ANY /": {
            "function_name": "pureleap-web--__index",
            "route": "ANY /"
          },
          "ANY /blog": {
            "function_name": "pureleap-web-blog",
            "route": "ANY /blog"
          },
          "ANY /recipes": {
            "function_name": "pureleap-web-recipes",
            "route": "ANY /recipes"
          },
          "ANY /categories/{id}": {
            "function_name": "pureleap-web-categories-_id_",
            "route": "ANY /categories/{id}"
          },
          "ANY /{post+}": {
            "function_name": "pureleap-web-_post__",
            "route": "ANY /{post+}"
          },
          "ANY /robots.txt": {
            "function_name": "pureleap-web-robots_txt",
            "route": "ANY /robots.txt"
          },
          "ANY /about": {
            "function_name": "pureleap-web-about",
            "route": "ANY /about"
          },
          "ANY /sitemap.xml": {
            "function_name": "pureleap-web-sitemap_xml",
            "route": "ANY /sitemap.xml"
          },
          "ANY /objecthub": {
            "function_name": "pureleap-web-objecthub",
            "route": "ANY /objecthub"
          },
          "ANY /tags/{id}": {
            "function_name": "pureleap-web-tags-_id_",
            "route": "ANY /tags/{id}"
          }
        },
        "domain": "dev-pureleap.home.mxro.de",
        "lambdaNamePrefix": "pureleap-web",
        "hostedZoneDomain": "mxro.de"
      },
      "awsUser": "awsUser",
      "awsRegion": "ap-southeast-2",
      "tfStateKey": "web-pureleap-dev-3348d36abfb49cd4d858.tfstate",
      "tfVersion": "1.6"
    },
    {
      "name": "prod",
      "configuration": {
        "lambdas": {
          "ANY /": {
            "function_name": "pureleap-web-prod--__index",
            "route": "ANY /"
          },
          "ANY /blog": {
            "function_name": "pureleap-web-prod-blog",
            "route": "ANY /blog"
          },
          "ANY /recipes": {
            "function_name": "pureleap-web-prod-recipes",
            "route": "ANY /recipes"
          },
          "ANY /categories/{id}": {
            "function_name": "pureleap-web-prod-categories-_id_",
            "route": "ANY /categories/{id}"
          },
          "ANY /{post+}": {
            "function_name": "pureleap-web-prod-_post__",
            "route": "ANY /{post+}"
          },
          "ANY /robots.txt": {
            "function_name": "pureleap-web-prod-robots_txt",
            "route": "ANY /robots.txt"
          },
          "ANY /about": {
            "function_name": "pureleap-web-prod-about",
            "route": "ANY /about"
          },
          "ANY /sitemap.xml": {
            "function_name": "pureleap-web-prod-sitemap_xml",
            "route": "ANY /sitemap.xml"
          },
          "ANY /objecthub": {
            "function_name": "pureleap-web-prod-objecthub",
            "route": "ANY /objecthub"
          },
          "ANY /tags/{id}": {
            "function_name": "pureleap-web-prod-tags-_id_",
            "route": "ANY /tags/{id}"
          }
        },
        "domain": "pureleap.com",
        "lambdaNamePrefix": "pureleap-web-prod",
        "hostedZoneDomain": "pureleap.com"
      },
      "awsUser": "awsUser",
      "awsRegion": "us-west-2",
      "tfStateKey": "web-pureleap-prod-c33db84b5eee3daf4d9a.tfstate",
      "tfVersion": "1.6"
    }
  ]
}